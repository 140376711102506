<template>
  <div class="content">
    <base-nav
      :type="pagesWithHeaderTransparent.includes($route.name)? 'transparent' : 'white'"
      class="navbar-expand nav-height pr-0 navbar-custom"
    >
      <Logo classes="navbar-brand position-static"></Logo>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <div v-if="!isAuthenticated" class="nav-link">
            <base-button
              v-if="$route.name !== 'login' && $route.name !== 'notFound'"
              simple
              class="position-relative btn-custon d-inline px-0"
              :class="
                pagesWithHeaderTransparent.includes($route.name) && scrollY < 352
                  ? 'text-white border-white-10'
                  : ''
              "
              @click="goToLogin"
              >{{ $tc("login") }}</base-button
            >
            <base-button
              v-if="$route.name === 'login'"
              simple
              class="position-relative btn-custon ml-0"
              :class="
                pagesWithHeaderTransparent.includes($route.name) && scrollY < 352
                  ? 'text-white border-white-50'
                  : ''
              "
              @click="goToRegister"
              >{{ $tc("register") }}</base-button
            >
          </div>
          <div v-else class="nav-link">
            <drop-down>
              <a
                href="#"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <div class="photo">
                  <img
                    v-if="profilePicture"
                    :src="profilePicture"
                    @error="replaceByDefault"
                    class="profile-img"
                    alt="Profile Photo"
                    width="100%"
                    height="100%"
                  />
                  <img
                    v-else
                    src="@/assets/img/anime3.png"
                    alt="Profile Photo"
                    class="profile-img"
                    width="100%"
                    height="100%"
                  />
                </div>
                <strong class="caret d-none d-lg-block d-xl-block"></strong>
              </a>
              <ul class="dropdown-menu dropdown-navbar bg-white mx-0">
                <li class="nav-link mb-2 mb-md-1 mb-lg-0" @click="goToEditProfile">
                  <a
                    href="javascript:void(0)"
                    class="nav-item dropdown-item text-black-50"
                  >
                    <em class="tim-icons icon-single-02"></em>
                    {{ $t("myProfile") }}</a
                  >
                </li>

                <li v-if="isCustomer" class="nav-link mb-2 mb-md-1 mb-lg-0" @click="goToMyAppointmets">
                  <a
                      href="javascript:void(0)"
                      class="nav-item dropdown-item text-black-50"
                  >
                    <em class="tim-icons icon-calendar-60"></em>
                    {{ $t("myAppointments") }}</a
                  >
                </li>

                <li class="nav-link mb-2 mb-md-1 mb-lg-0" @click="logout()">
                  <a
                    href="javascript:void(0)"
                    class="nav-item dropdown-item text-black-50"
                  >
                    <em class="tim-icons icon-button-power"></em>
                    {{ $t("logout") }}</a
                  >
                </li>
              </ul>
            </drop-down>
          </div>
        </li>
      </ul>
    </base-nav>
    <public-pages-content></public-pages-content>
    <footer-custom></footer-custom>
  </div>
</template>

<script>
import { BaseNav, Logo } from "@/components/index";
import { mapActions, mapState } from "vuex";
import DropDown from "@/components/white-dashboard/Dropdown.vue";
import PublicPagesContent from "./NavbarContent";
import useLogout from "../Auth/useLogout";
import FooterCustom from "../../components/FooterCustom";
import defaultImage from "../../assets/img/image_placeholder.jpg";

export default {
  name: "NavBarLayout",
  data() {
    return {
      route: "/auth/logout",
      home: "/",
      dashboard: "/dashboard",
      profilePicture: null,
      scrollY: null,
      authenticate: this.isAuthenticated,
      pagesWithHeaderTransparent: ['storeList', 'storeListFiltered', 'whoWeAre', 'useGuide', 'notFound', 'integrations']
    };
  },
  computed: {
    ...mapState([
      "isAuthenticated",
      "isAdmin",
      "isManager",
      "isEmployee",
      "isCustomer",
      "changedProfilePicture",
      "storeName",
      "hasChangeForm",
    ]),
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    async logout() {
      if (!this.hasChangeForm) {
        const response = await useLogout();
        if (response && response.data.status === 'success') {
          await this.$router.push({name: "login"});
          return;
        }
        this.$toast.error( this.$t("notifications.error.general") );
      } else {
        await this.$router.push({name: "login"});
      }
    },
    goToEditProfile() {
      if(this.$router.history.current.path !== "/profile") {
        this.$store.dispatch("setPreviousPage", location.pathname);
        this.$router.push("/profile");
      }
    },
    goToMyAppointmets() {
      if(this.$router.history.current.name !== "myAppointments") {
        this.$store.dispatch("setPreviousPage", location.pathname);
        this.$router.push({ name: "myAppointments" });
      }
    },
    handleScroll() {
      this.scrollY = scrollY;
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    ...mapActions(["setPreviousPage"]),
  },
  mounted() {
    if (localStorage.getItem("profilePicture"))
      this.profilePicture =
        localStorage.getItem("profilePicture") !== "null"
          ? localStorage.getItem("profilePicture")
          : null;
  },
  watch: {
    changedProfilePicture() {
      this.profilePicture =
        localStorage.getItem("profilePicture") !== "null"
          ? localStorage.getItem("profilePicture")
          : null;
    },
    isAuthenticated() {
      this.authenticate = this.isAuthenticated;
      this.profilePicture =
        localStorage.getItem("profilePicture") !== "null"
          ? localStorage.getItem("profilePicture")
          : null;
    },
  },
  created() {
    addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll);
  },
  components: {
    PublicPagesContent,
    BaseNav,
    DropDown,
    Logo,
    FooterCustom
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .navbar-custom{
    height: 70px!important;
  }
}
.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-custon {
  width: 120px;
}
.nav-height {
  height: 100px;
}
</style>
