<template>
  <div class="w-100 p-0">
    <card class="mb-0">
        <div class="row justify-content-between">
          <div class="col-12 col-md-2 col-lg-1 order-1 d-flex flex-row flex-md-column justify-content-around align-items-center mb-2 icons-footer">
            <a :href="contactInfo.urlFacebook" target="_blank"  class="social-icon rounded-circle bg-light m-1" rel="nofollow">
              <img
                src="../assets/img/fb-logo.png"
                class="social-img p-2"
                alt="facebook logo"
                width="37rem"
                height="37rem"
              />
            </a>
            <a :href="contactInfo.urlInstragram" target="_blank"  class="social-icon rounded-circle bg-light m-1" rel="nofollow">
              <img
                loading="lazy"
                src="../assets/img/instagram-logo.png"
                class="social-img p-2"
                alt="instagram logo"
                width="37rem"
                height="37rem"
              />
            </a>
            <a :href="contactInfo.urlTwitter" target="_blank"  class="social-icon rounded-circle bg-light m-1" rel="nofollow">
              <img
                loading="lazy"
                src="../assets/img/twitter-logo.png"
                class="social-img p-2"
                alt="twitter logo"
                width="37rem"
                height="37rem"
              />
            </a>
            <div class="social-icon rounded-circle m-1">
              <img
                loading="lazy"
                src="../assets/img/Logo_AS_Transparent.png"
                class=""
                alt="appoiment logo"
                width="37rem"
                height="37rem"
              />
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-5 order-2 my-2 my-0-md">
              <div class="d-flex gap-2 col-11 col-md-12 mx-auto card-footer row justify-content-between h-100">
                  <a v-for="link in footerNav[0]" :href="link.route" :key="link.name" class="col-12 col-sm-6 mb-3 text-md-left text-center" rel="nofollow">
                      <span class="footer-link font-weight-bold text-dark" >{{$t(`footerLink.${link.name}`)
                  }}</span>
                  </a>

              </div>
          </div>
          <div class="col-12 col-lg-2 order-last order-lg-3 d-flex flex-lg-column justify-content-center justify-content-lg-start">
            <a
              v-for="link in footerNav[1]"
              :key="link.name"
              class="p-2 pointer legalLinks"
              :href="link.route"
            >
              {{ $t(`footerLink.${link.name}`) }}
            </a>
          </div>
          <div class="col-12 col-md-5 col-lg-4 order-4 my-3">
            <div
              class="d-flex flex-column align-items-center align-items-md-end justify-content-between h-100 mr-3"
            >
            <h3 class="text-muted">{{$t('techAssistance')}} AS:</h3>
              <h3 class="mb-0 text-center">
                 <a :href="`tel:${contactInfo.phone}`" class="text-muted">
                  {{ contactInfo.phone }}
                 </a>
                  <em class="tim-icons ml-2 icon-mobile"></em>
              </h3>
              <h3 class="mb-0 text-center">
                 <a :href="`https://web.whatsapp.com/send?phone=${contactInfo.whatsApp}`" class="text-muted">
                  WhatsApp
                 </a>
                 <img
                    loading="lazy"
                    src="../assets/img/whatsapp.png"
                    class=""
                    alt="whatsapp logo"
                    width="25rem"
                    height="25rem"
                  />
              </h3>
              <h3>
                <a :href="`mailto:${contactInfo.email}`" class="text-muted">
                  {{ contactInfo.email }}
                </a>
                  <em class="tim-icons ml-2 icon-email-85"></em>
              </h3>
              <a class="d-none d-md-block" :href="contactInfo.itexonUrl" target="_blank">
                <img
                  loading="lazy"
                  src="../assets/img/itexon-logo.svg"
                  width="135px"
                  height="50px"
                  alt="itexon logo"
                  class="itexon-logo"
                />
              </a>
            </div>
          </div>
        </div>
    </card>
    <div class="text-center p-2">
     &copy; {{ new Date().getFullYear() }} {{ contactInfo.title }} {{ $t("footerLink.copyright") }}
    </div>
  </div>
</template>
<script>
import { Card } from "@/components/index";

export default {
  name: "footer-custom",
  data: () => {
    return {
      appName: process.env.VUE_APP_AS_NAME,
      footerNav: [
        [
          {
            name: "whoWeAre",
            route: "/who-we-are",
          },
          {
            name: "joinUs",
            route: "/promociona-tu-negocio",
          },
          {
            name: "freqAnswers",
            route: null,
          },
          {
            name: "contact",
            route: null,
          },
          {
            name: "useGuide",
            route: "/use-guide",
          },
          {
            name: "integrations",
            route: "/integrations",
          }
        ],
        [
          {
            name: "cookies",
            route: "/cookies",
          },
          {
            name: "privacyPolicy",
            route: '/politica-de-privacidad',
          },
          {
            name: "legalNotice",
            route: "/aviso-legal",
          },
          {
            name: "termsAndConditions",
            route: "/terminos-y-condiciones",
          },
        ],
      ],
      contactInfo: {
        email: process.env.VUE_APP_INFO_AS_SALOON || 'info@as-saloon.com',
        urlFacebook: process.env.VUE_APP_AS_SALOON_FACEBOOK || 'https://www.facebook.com/As-Saloon-102193499076837',
        urlInstragram: process.env.VUE_APP_AS_SALOON_INSTAGRAM || 'https://www.instagram.com/as_saloon_oficial/',
        urlTwitter: process.env.VUE_APP_AS_SALOON_TWITTER || 'https://twitter.com/As_Saloon_of',
        itexonUrl: process.env.VUE_APP_ITEXON_URL || 'https://itexon.es/',
        phone: process.env.VUE_APP_AS_SALOON_PHONE || "615693017",
        whatsApp: process.env.VUE_APP_AS_SALOON_WHATSAPP || "615693017",
        title: process.env.VUE_APP_ITEXON_TITLE || 'ITEXON SYSTEMS'
      }
    };
  },
  components: {
    Card,
  },
};
</script>
<style scoped lang="scss">
.icons-footer{
  padding:0 10%
}
.social-icon {
  max-width: 50px;
  max-height: 50px;
}
.itexon-logo {
  max-height: 50px;
}
div.card-footer{
  background-color:#e7e9f2;
  border-radius: 15px
}
.footer-link{
    cursor: pointer;
    padding: 10px 5px;
}
.pointer {
  cursor: pointer;
}
.legalLinks {
  color: black!important;
}
@media (min-width: 568px) {
  .icons-footer{
    padding: 0
  }
  .social-icon {
    max-width: 2.3rem;
    max-height: 2.3rem;
  }
}
</style>
